export const ONSUCCESS = {
    "en" :  {
        "title": "Payment Process successfully completed",
        "message": "An email was sent to you, kindly check the email you registered with to start the sign up process.",
        "note": ["Please check your spam if you didn't find the email in your inbox.", "If you didn't get the email please", "Contact us", "and leave a note and we'll get back to you as soon as possible."]
    },
    "ar": {
        "title": "Payment Process successfully completed",
        "message": "An email was sent to you, kindly check the email you registered with to start the sign up process.",
        "note": [
                "Please check your spam if you didn't find the email in your inbox.", 
                "If you didn't get the email please", 
                "Contact us", 
                "and leave a note and we'll get back to you as soon as possible."
                ]
    }
}

export const ONFAIL = {
    "en": "Payment Process cannot proceed",
    "ar": "Payment Process cannot proceed"
}

export const ERRORS = {
    "en": ["Invalid URL", "Payment Problem"],
    "ar": ["Invalid URL", "Payment Problem"]
}

export const STEPS = {
    "en": ["Loading payment", "Saving payment", "Sending you email"],
    "ar": ["Loading payment", "Saving payment", "Sending you email"]
}


export const IMPROVE = {
    "en": "Improve Now!",
    "ar": "Improve Now!"
}