import React from 'react'
import styled from "styled-components"

const Spinner =  styled.div`

.spinner {
  width: ${props => props.size? props.size : "45px"};
  height: ${props => props.size? props.size : "45px"};
  position: relative;
  text-align: center;
  align-self: center;
  margin:  ${props => props.margin? props.margin : "auto"};
  -webkit-animation: sk-rotate 2.0s infinite linear;
  animation: sk-rotate 2.0s infinite linear;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: ${props => props.theme.colors.yellowMelior};
  border-radius: 100%;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
`

export default function spinner({size, margin}) {
    return (
        <Spinner size={size} margin={margin}>
            <div className="spinner">
              <div className="dot1"></div>
              <div className="dot2"></div>
            </div>
        </Spinner>
    )
}
